const localization = {
  home: {
    title: {
      en: "Home page",
      ua: "Домашня сторінка",
      ru: "Домашняя страница",
    },
  },

  news: {
    title: {
      en: "News",
      ua: "Новини",
      ru: "Новости",
    },
    showMore: {
      en: "Show more...",
      ua: "Показати більше...",
      ru: "Показать больше...",
    },
  },

  projects: {
    title: {
      en: "Projects",
      ua: "Проекти",
      ru: "Проекты",
    },
    readMore: {
      en: "More Details...",
      ua: "Детальніше...",
      ru: "Подробнее...",
    },
    playOnline: {
      en: "Play online",
      ua: "Грати онлайн",
      ru: "Играть онлайн",
    },
  },

  apps: {
    title: {
      en: "Examples",
      ua: "Приклади",
      ru: "Примеры",
    },
    gameTitle: {
      en: "Games",
      ua: "Ігри",
      ru: "Игры",
    },
    open: {
      en: "Open application",
      ua: "Відкрити додаток",
      ru: "Открыть приложение",
    },
    notForMobiles: {
      en: "The application is not adapted for mobile devices.",
      ua: "Додаток не адаптований для мобільних пристроїв.",
      ru: "Приложение не адаптировано для мобильных устройств.",
    },
    openDesktop: {
      en: "Please try to open the desktop version.",
      ua: "Будь ласка, спробуйте відкрити десктопну версію.",
      ru: "Пожалуйста, попробуйте открыть десктопную версию.",
    },
    tryToOpen: {
      en: "Try to open anyway",
      ua: "Все одно спробувати відкрити",
      ru: "Всё равно попробовать открыть",
    },
    error_1: {
      en: "Error loading the application... :(",
      ua: "Помилка при завантаженні додатку... :(",
      ru: "Ошибка при загрузке приложения… :(",
    },
    error_2: {
      en: "If refreshing the page doesn't help — please write to me.",
      ua: "Якщо оновлення сторінки не допомагає — напишіть мені, будь-ласка.",
      ru: "Если обновление страницы не помогает — напишите мне, пожалуйста.",
    },
  },

  articles: {
    title: {
      en: "Articles",
      ua: "Статті",
      ru: "Статьи",
    },
    readMore: {
      en: "More Details...",
      ua: "Детальніше...",
      ru: "Подробнее...",
    },
    postedOn: {
      en: "Posted on ",
      ua: "Опубліковано ",
      ru: "Опубликовано ",
    },
  },

  documents: {
    title: {
      en: "Documents",
      ua: "Документи",
      ru: "Документы",
    },
  },

  contacts: {
    title: {
      en: "If you want more...",
      ua: "Якщо потрібно більше...",
      ru: "Если нужно больше...",
    },
    menu: {
      en: "Contacts",
      ua: "Контакти",
      ru: "Контакты",
    },
  },

  pageNotFound: {
    title: {
      en: "404 - Page not found",
      ua: "404 - Сторінка не знайдена",
      ru: "404 - Страница не найдена",
    },
    text: {
      en: "Bullshit, I have lost this page! Or maybe you wrote wrong URL? 🤔 Try to find something else, please.",
      ua: "Щоб тебе хрущі з'їли, я не можу знайти цю сторінку! Чи може ти щось наклацав? 🤔 В будь-якому разі, спробуй пошукати щось інше.",
      ru: "Чёрт бы его побрал, у меня нет такой страницы! Или это ты ищешь то, чего нет? 🤔 В общем, попробуй перейти на другую страницу.",
    },
  },
};

localization.menu = {
  en: {
    home: localization.home.title.en,
    news: localization.news.title.en,
    projects: localization.projects.title.en,
    articles: localization.articles.title.en,
    documents: localization.documents.title.en,
    contacts: localization.contacts.menu.en,
  },
  ua: {
    home: localization.home.title.ua,
    news: localization.news.title.ua,
    projects: localization.projects.title.ua,
    articles: localization.articles.title.ua,
    documents: localization.documents.title.ua,
    contacts: localization.contacts.menu.ua,
  },
  ru: {
    home: localization.home.title.ru,
    news: localization.news.title.ru,
    projects: localization.projects.title.ru,
    articles: localization.articles.title.ru,
    documents: localization.documents.title.ru,
    contacts: localization.contacts.menu.ru,
  },
};

export { localization };
